<template>
	<div class="settings-content">
		<div class="row-space-tbf">
			<div class="space-left"></div>
			<div class="content software-updates-page" v-if="loaded">
				<template v-if="announceData">
					<div class="header-update">
						<div class="title">{{ announceData.title }}</div>

						<div class="crud-actions" v-if="announceData.rights.edit_delete">
							<button class="btn-tbf white only-icon open-sidebar" @click="showModal('list_updates')" v-if="$resize && $mq.below(820)">
								<icon-settings />
							</button>
							<button class="btn-tbf grey center only-icon" @click="$router.push({name: 'announcements-edit', params: {id: announceData.id }})"><icon-edit /></button>
							<button class="btn-tbf white center only-icon" @click="showModal('delete', {type: 'announcement', from: 'index_announcements', model: announceData})"><icon-trash class="red"/></button>
						</div>
					</div>
					<div class="target-users">
						<template v-if="announceData.departments.length">
							<div class="tag-department" v-for="department in announceData.departments" :style="`--departmnetColor: ${department.color_code}; --bgDepartmnetColor: ${department.color_code}20;`" :class="{'without-color': !department.color_code}">{{ department.name }}</div>
						</template>
						<div class="tag-department without-color" v-else>{{ $t('announcements.target_company') }}</div>
					</div>
					<div class="content-update" v-html="announceData.body" v-if="announceData.body"></div>

					<div class="attachments-list" v-if="announceData.files.length">
						<div class="title"><icon-paper-clip /> {{ $t('announcements.attachments') }}</div>
						<div class="list-files">
							<a class="file-item" v-for="file in announceData.files" :href="file.file_path" target="_blank">
								<div class="icon"><icon-file /></div>
								<div class="name">{{ file.filename }}</div>
							</a>
						</div>
					</div>

					<div class="comments-section">
						<div class="title-section">{{ $t('announcements.comments') }}</div>
						<div class="list-comments" v-if="announceData.comments.length">
							<div class="comment-item" v-for="comment in announceData.comments">
								<div class="user-data">
									<img :src="comment.author.avatar"/>
								</div>
								<div class="comment-content">
									<div class="header-comment">
										<div class="name-date">
											{{ comment.author.name }}
											<span class="date">{{ comment.updated_at | moment('DD MMM YYYY hh:ss') }}</span>
											<span class="is-edited" v-if="comment.is_updated">{{ $t('announcements.is_edited') }}</span>
										</div>
										<div class="actions" v-if="comment.rights.edit_delete">
											<button @click="editComment(comment)"><icon-edit /></button>
											<button @click="showModal('delete', {type: 'comment', from: 'show_announcement', model: comment})"><icon-trash class="red"/></button>
										</div>
									</div>

									<div class="input-comment" v-bind:class="{has_error: $v.commentContentEdit.$error}" v-if="commentInEdit == comment.id">
										<quill-editor class="editor-tbf reverse-position" v-model="commentContentEdit" :options="editorOption">
											<template #toolbar>
												<div id="my-toolbar-tbf">
													<button class="ql-bold"></button>
													<button class="ql-italic"></button>
													<button class="ql-underline"></button>
													<button class="ql-strike"></button>
													<div class="separator-toolbar"></div>

													<button class="ql-link"></button>
													<div class="separator-toolbar"></div>

													<button class="ql-clean"></button>
													<div class="separator-toolbar"></div>

													<button id="button-cancel-comment" class="btn-tbf grey center" @click="cancelComment('update')">
														<div class="text">{{ $t('general.cancel') }}</div>
													</button>
													<button id="button-update-comment" class="btn-tbf blue center" @click="submitForm">
														<div class="loader-spin" v-if="loadingSendComment"></div>
														<template v-else>
															<div class="text">{{ $t('general.update') }}</div>
														</template>												
													</button>
												</div>
											</template>
										</quill-editor>
									</div>
									<div class="comment-data" v-html="comment.body" v-else></div>
								</div>
							</div>
						</div>
						<div class="empty-comments" v-else>{{ $t('announcements.empty_comments') }}</div>

						<div class="form-comment" id="formComment">
							<div class="title-new-comment">
								<img :src="$auth.user().avatar"/>
								<div class="placeholder">{{ $t('announcements.add_new_comment') }}</div>
							</div>
							<div class="input-comment" v-bind:class="{has_error: $v.commentContent.$error}">
								<quill-editor class="editor-tbf reverse-position" v-model="commentContent" :options="editorOption">
									<template #toolbar>
										<div id="my-toolbar-tbf">
											<button class="ql-bold"></button>
											<button class="ql-italic"></button>
											<button class="ql-underline"></button>
											<button class="ql-strike"></button>
											<div class="separator-toolbar"></div>

											<button class="ql-link"></button>
											<div class="separator-toolbar"></div>

											<button class="ql-clean"></button>
											<div class="separator-toolbar"></div>

											<button id="button-cancel-comment" class="btn-tbf grey center" @click="cancelComment('store')">
												<div class="text">{{ $t('general.cancel') }}</div>
											</button>
											<button id="button-send-comment" class="btn-tbf blue center" @click="submitForm">
												<div class="loader-spin" v-if="loadingSendComment"></div>
												<template v-else>
													<div class="icon"><icon-send /></div>
													<div class="text">{{ $t('general.send') }}</div>
												</template>												
											</button>
										</div>
									</template>
								</quill-editor>
							</div>
						</div>
					</div>
				</template>
				<div class="message-empty-update" v-else>{{ $t('announcements.empty_list') }}</div>
			</div>
			<div class="content" v-else>
				<div class="content-update placeholder-loader" style="height: 200px; width: 100%;"></div>
				<div class="placeholder-loader" style="height: 300px; width: 100%; margin-top: 15px;"></div>
				<div class="placeholder-loader" style="height: 100px; width: 100%; margin-top: 15px;"></div>
			</div>
			<div class="space-right"></div>
		</div>
	</div>
</template>

<script>
    import IconArrow from '../../Icons/Arrow.vue'
	import IconSettings from '../../Icons/Settings'
	import IconSend from '../../Icons/Send'
	import IconEdit from '../../Icons/Edit'
	import IconTrash from '../../Icons/Trash'
	import IconFile from '../../Icons/File'
	import IconPaperClip from '../../Icons/PaperClip'

	import Quill from 'quill'
	import ImageUploader from "quill-image-uploader/src/quill.imageUploader.js"
    import "quill-mention";
	import { required, requiredIf } from 'vuelidate/lib/validators'
	import InfiniteLoading from 'vue-infinite-loading'

	Quill.register("modules/imageUploader", ImageUploader);

	export default {
		components: {
			IconArrow,
			IconSettings,
			IconSend,
			IconEdit,
			IconTrash,
			IconFile,
			IconPaperClip,
			InfiniteLoading
		},
		data() {
			return {
				loaded: false,
				infiniteId: 1,
				announceData: '',
				softwareUpdates: [],
				typeUrlShow: '',
				commentContent: '',
				editorOption: {
					theme: 'snow',
                    placeholder: this.$t('announcements.description-ph'),
                    modules:{
						toolbar: "#my-toolbar-tbf",
						imageUploader: {
							upload: (file) => {
								return new Promise((resolve, reject) => {
									let objData = new FormData()
									objData.append('file', file)
									objData.append('type', 'announces')

									axios.post(`/instances/${this.$auth.user().instance.id}}/upload-image`, objData)
									.then(({data}) => {
										resolve(data.file_path);
									})
								});
							},
						},
                        mention: {
                            allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
                            mentionDenotationChars: ["@"],
                            source: async (searchTerm, renderList) => {
                                const matchedPeople = await this.suggestPeople(searchTerm);
                                renderList(matchedPeople, searchTerm);
                            },
                            dataAttributes: ['id', 'name']
                        }
					}
                },
				commentInEdit: '',
				commentContentEdit: '',
				loadingSendComment: false,
				forceRefresh: false
			};
		},
		validations: {
			commentContent: { required: requiredIf(function () {
				return this.commentInEdit == '';
			})},
			commentContentEdit: { required: requiredIf(function () {
				return this.commentInEdit;
			})},
		},
		watch:{
			$route (to, from){
				if(to.params.id) {
					this.getCompanyAnnouncement()
					var title =  this.$t('announcements.title', { company: this.$auth.user().instance.company_name });
					this.$root.$emit("navbar_title", title);
				}
			}
		},
		async mounted(){
			var title =  this.$t('announcements.title', { company: this.$auth.user().instance.company_name });
			this.$root.$emit("navbar_title", title);
            
			await this.getFilters();
			if(this.$route.params && this.$route.params.id){
				await this.getCompanyAnnouncement();
			}

			if(['announcements'].includes(this.$route.name)) {
				await this.getAnnouncements();
			}

			this.$root.$on('refresh_announcement', () => {
				this.getCompanyAnnouncement()
			})

			this.$root.$on('showEmptyAnnouncement', () => {
				this.announceData = '';
				var title =  this.$t('announcements.title', { company: this.$auth.user().instance.company_name });
				this.$root.$emit("navbar_title", title);
				this.loaded = true
				setTimeout(() => {
					$('.opacity-page').addClass('show')
				}, 0)
			})
		},
		methods: {
			async getFilters(){
				var filtersDB = {
					users: true 
				}

				await axios.get( `instances/${this.$auth.user().instance.id}/filter`, { params: filtersDB })
				.then(({data}) => {
        			this.optionsUsers = data.data.users
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
			},
			async getAnnouncements() {
				this.typeUrlShow = 'announcements-show';

				await axios.get(`${this.$auth.user().instance.id}/announces`)
				.then(({data}) => {
					let listData = data.data

					if(['announcements'].includes(this.$route.name)) {
						if(listData.length) {
							if(listData.find(el => !el.seen)) {
								this.$router.push({ name: this.typeUrlShow, params: { id: listData.find(el => !el.seen).id }})
							} else {
								this.$router.push({ name: this.typeUrlShow, params: { id: listData[0].id }})
							}
						} else {
							var title =  this.$t('announcements.title', { company: this.$auth.user().instance.company_name });
							this.$root.$emit("navbar_title", title);
							this.loaded = true
							setTimeout(() => {
								$('.opacity-page').addClass('show')
							}, 0)
						}
					}
				})
			},
			getCompanyAnnouncement(){
				axios.get(`/announces/${this.$route.params.id}/show`)
				.then(({data}) => {
					this.announceData = data.data
				})
				.catch(error => {
					if(error.response){
						if(error.response.status == 403) {
							this.$router.push({name: 'forbbiden'})
						}else if(error.response.status == 404) {
							this.$router.push({name: 'not-found'})
						} else if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
				.finally(() => {
					this.loaded = true
					setTimeout(() => {
						if(!this.announceData.seen){ this.markAsSeen() }
					}, 0)
				})
			},
			markAsSeen(){
				axios.post(`/announces/${this.announceData.id}/mark-as-seen`)
				.then(() => {
					this.$auth.fetch()
				})
			},
			async suggestPeople(searchTerm){
                return this.optionsUsers.filter( (user) => user.name.toUpperCase().includes(searchTerm.toUpperCase())).map((el) => { return {id: el.id, value: el.name} });
            },
			submitForm() {
				var buttonSend = document.querySelector(this.commentInEdit ? '#button-update-comment' : '#button-send-comment');
				buttonSend.disabled = true;
				this.loadingSendComment= true;

				this.$v.$touch()

				if(!this.$v.$invalid){
					let objForm = {
						announce_id: this.announceData.id,
						body: this.commentInEdit ? this.commentContentEdit : this.commentContent,
						tag_user_ids: this.getMentionsIds()
					}

					if(this.commentInEdit) { 
						this.updateComment(objForm, this.commentInEdit, buttonSend);
					} else {
						this.storeComment(objForm, buttonSend);
					}
				} else {
					buttonSend.disabled = false;
					this.loadingSendComment= false;
				}
			},
			storeComment(objForm, buttonSend) {
				axios.post('/comments/store', objForm)
				.then(() => {
					this.getCompanyAnnouncement();
					this.$v.$reset();
				})
				.finally(() => {
					this.commentContent = '';
					buttonSend.disabled = false;
					this.loadingSendComment= false;
				});
			},
			updateComment(objForm, commentId, buttonSend) {
				axios.patch(`/comments/${commentId}`, objForm)
				.then(() => {
					this.getCompanyAnnouncement();
					this.$v.$reset();
				})
				.finally(() => {
					this.commentContentEdit = '';
					this.commentInEdit = '';
					buttonSend.disabled = false;
					this.loadingSendComment= false;
				});
			},
			getMentionsIds() {
				let arrayMentions = [...document.querySelectorAll('.ql-editor [data-id]')];
				let arrayMentionsIds = [];

				if(arrayMentions.length) {
					arrayMentions.map(el => {
						arrayMentionsIds.push(el.attributes['data-id'].value);
					})
				}

				return arrayMentionsIds;
			},
			editComment(comment) {
				this.commentContentEdit = comment.body;
				this.commentInEdit = comment.id;

				// document.getElementById('formComment').scrollIntoView({
					// behavior: 'smooth'
				// });
			},
			cancelComment(type) {
				this.$v.$reset();
				if(type == 'store') {
					this.commentContent = '';
				} else {
					this.commentContentEdit = '';
					this.commentInEdit = '';
				}
			},
			showModal(type, data = false, itemId = false){
				this.$root.$emit('open_modal', type, data, itemId);
			}
		}
	};
</script>